import React from 'react';
import './Home.css'; // Gaya CSS untuk halaman Home
import { Link } from 'react-router-dom';

const Home = () => {
  const uid_talenta = localStorage.getItem('uid_talenta');

  const handleRedirect = (url) => {
    window.open(url, '_blank');
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('uid_talenta');
    window.location.reload();
  }

  return (
    <div className="home-container">
      <h1>Welcome to the SSO Home Page</h1>
      <p>Select a project to access:</p>
      <div className="button-container">
        <button className="redirect-button" onClick={() => handleRedirect(process.env.REACT_APP_BALANCE_URL)}>
          Balance
        </button>
        <button className="redirect-button" onClick={() => handleRedirect(process.env.REACT_APP_SECOND_PROJECT_URL)}>
          Second Project
        </button>
        <button className="redirect-button" onClick={() => handleRedirect(process.env.REACT_APP_THIRD_PROJECT_URL)}>
          Third Project
        </button>
        <Link to={`/employee/${uid_talenta}`}>
            <button className="redirect-button">
                Profile
            </button>
        </Link>
        <button className="logout-button" onClick={() => handleLogout()}>
          Logout
        </button>
      </div>
    </div>
  );
};

export default Home;

import React from 'react';
import { Navigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';

const ProtectedRoute = ({ children }) => {
    const token = localStorage.getItem('token'); // Ambil token dari localStorage

    if (!token) {
        localStorage.removeItem('uid_talenta'); // Hapus uid_talenta jika tidak ada token
        return <Navigate to="/" />;
    }

    // Validasi token (jika perlu)
    try {
        const decodedToken = jwtDecode(token);
        const currentTime = Date.now() / 1000; // Waktu saat ini dalam detik

        // Simpan uid_talenta di localStorage
        localStorage.setItem('uid_talenta', decodedToken.uid_talenta);

        // Cek apakah token sudah kedaluwarsa
        if (decodedToken.exp < currentTime) {
            // Jika token sudah kedaluwarsa, hapus token dan uid_talenta dari localStorage
            localStorage.removeItem('token');
            localStorage.removeItem('uid_talenta');
            return <Navigate to="/" />;
        }
    } catch (error) {
        // Jika ada kesalahan saat mendekode token, anggap token tidak valid
        localStorage.removeItem('token');
        localStorage.removeItem('uid_talenta'); // Hapus uid_talenta jika token tidak valid
        return <Navigate to="/" />;
    }

    // Jika token valid, render anaknya (children)
    return children;
};

export default ProtectedRoute;

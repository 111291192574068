import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const NotFound = () => {
    const navigate = useNavigate();

    useEffect(() => {
        // Mengarahkan ke halaman Login setelah beberapa detik
        const timer = setTimeout(() => {
            navigate('/'); // Arahkan ke halaman Login
        }, 3000); // Waktu tunda 3 detik

        return () => clearTimeout(timer); // Bersihkan timer saat komponen di-unmount
    }, [navigate]);

    // Gaya CSS dalam objek
    const notFoundStyle = {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        textAlign: 'center',
        backgroundColor: '#f9f9f9',
        color: '#333',
    };

    return (
        <div style={notFoundStyle}>
            <h2 style={{ margin: '0' }}>404 - Page not found.</h2>
            <h2 style={{ margin: '0' }}>You will be redirected.</h2>
        </div>
    );
};

export default NotFound;

import React, { useState } from 'react';
import axios from 'axios';
import './Login.css'; // Gaya CSS untuk halaman login
import companyLogo from '../assets/image/Logo Perusahaan Typing.png';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault(); // Mencegah refresh halaman saat form disubmit
    setError(''); // Reset error message
    const url_api = process.env.REACT_APP_SSO_API_URL;
    const url_fe = process.env.REACT_APP_SSO_URL;

    try {
      // Mengirimkan permintaan ke endpoint login
      const response = await axios.post(url_api + '/auth/login', {
        email,
        password,
      });
      
      // Jika berhasil, simpan token di local storage
      localStorage.setItem('token', response.data.token);

      // Ambil parameter redirect dari URL
      const urlParams = new URLSearchParams(window.location.search);
      const redirectUrl = urlParams.get('redirect') || '/home'; // Ganti dengan URL default jika tidak ada

      // Buat URL yang sesuai untuk redirect
      const currentDomain = window.location.origin; // Ambil domain saat ini (misalnya, localhost:3002)
      const redirectDomain = new URL(redirectUrl, currentDomain).origin;

      // Redirect ke URL yang ditentukan hanya jika domain berbeda
      if (redirectDomain !== currentDomain) {
        window.location.href = `${redirectUrl}?token=${response.data.token}`;
      } else {
        window.location.href = url_fe + '/home';
      }
    } catch (err) {
      // Menangani error jika login gagal
      setError('Login failed. Please check your credentials and try again.');
      console.error('Login error:', err);
    }
  };

  return (
    <div className="login-container">
      <div className="login-card">
        <img src={companyLogo} alt="Company Logo" className="company-logo-type" />
        <form className="login-form" onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="email">Email</label>
            <input
              type="email"
              id="email"
              placeholder="Enter your email"
              required
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className="form-group">
            <label htmlFor="password">Password</label>
            <input
              type="password"
              id="password"
              placeholder="Enter your password"
              required
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          {error && <div className="error-message">{error}</div>}
          <button type="submit" className="login-button">Login</button>
        </form>
      </div>
    </div>
  );
};

export default Login;

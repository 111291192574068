import React from 'react';
import { useParams } from 'react-router-dom';
import './EmployeeDetail.css'; // Gaya CSS untuk mempercantik tampilan

// Mengimpor gambar logo dan foto profil dari assets
import companyLogo from '../assets/image/Logo Perusahaan.png';

const EmployeeDetail = () => {
  // Mengambil ID karyawan dari URL
  const { id } = useParams();

  // Data karyawan sementara (mock) di frontend
  const employeeData = {
    '2783604': {
      companyName: 'PT. Hasil Karya Sentra Pangan',
      employeeName: 'Andrey Desayli',
      employeeId: 'HK 6503 APR',
      division: 'Programming',
      position: 'Administrator Programming',
      status: 'Active Employee',
      active: true, // Status aktif
      photo: require('../assets/image/andre.png'), // Path foto Andrey
    },
    '2783605': {
      companyName: 'PT. Hasil Karya Sentra Pangan',
      employeeName: 'Budi Santoso',
      employeeId: '-',
      division: '-',
      position: '-',
      status: 'Inactive Employee',
      active: false, // Status tidak aktif
      photo: require('../assets/image/budi.png'), // Path foto Budi
    },
    // Anda bisa menambahkan data mock lainnya di sini
  };
  
  // Memuat data berdasarkan ID dari URL
  const employee = employeeData[id];

  // Jika ID tidak ditemukan dalam data mock
  if (!employee) {
    return <div>Employee not found!</div>;
  }

  // Fungsi untuk menangani aksi tombol masuk
  const handleLoginClick = () => {
    if (employee.active) {
      // Tambahkan logika untuk mengarahkan ke sistem, misalnya:
      window.location.href = '/home';
    }
  };

  return (
    <div className="employee-detail-container">
      {/* Bagian atas menampilkan logo dan nama perusahaan */}
      <div className="company-info">
        <img src={companyLogo} alt="Company Logo" className="company-logo" />
        <h1 className="company-name">{employee.companyName}</h1>
      </div>

      {/* Kartu identitas karyawan */}
      <div className="employee-card">
        {/* Indikator status aktif */}
        <div className={`employee-status-indicator ${employee.active ? 'active' : 'inactive'}`}>
          <span className="status-circle"></span>
          <span className="status-text">{employee.status}</span>
        </div>
        <div className="employee-photo-container">
          <img src={employee.photo} alt="Employee Profile" className="employee-photo" />
        </div>
        <div className="employee-details">
          <h2 className="employee-name">{employee.employeeName}</h2>
          <p className="employee-id">ID: {employee.employeeId}</p>
          <p className="employee-division">Division: {employee.division}</p>
          <p className="employee-position">Position: {employee.position}</p>
        </div>

        {/* Tombol Masuk */}
        <button
          className="employee-login-button"
          onClick={handleLoginClick}
          disabled={!employee.active}
        >
          Login to System
        </button>
      </div>
    </div>
  );
};

export default EmployeeDetail;
